export const getBaseURL = () => {
    let url = (process.env.REACT_APP_API_HOST ? 'https://' : 'http://') 
            + (process.env.REACT_APP_API_HOST ? process.env.REACT_APP_API_HOST : `${window.location.url}:${process.env.REACT_APP_API_PORT || 8081}`);
    return url;
}

export const getAPIURL = (endpoint) => {
    let url =  `${getBaseURL()}/api/${endpoint}`;
    return url;
}

export const getImageURL = (image) => {
    let url = `${getBaseURL()}/uploads/${image}`
    return url;
}