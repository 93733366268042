import React, { useState, useEffect } from "react";
import logo from '../img/namopaimo.png'
import { Box, Typography } from "@mui/material";
import RegisterForm from '../forms/RegisterForm';
import CompleteForm from "../forms/CompleteForm";
import { getAPIURL } from "../helpers/api";
import axios from 'axios';

const RegisterCompletePage = (props) => {
    const [phase, setPhase] = useState('');

    useEffect(() => {
        // Check the current phase as per the API
        axios.get(getAPIURL('config')).then((res) => {
            let phase = res.data.filter(cfg => cfg.configkey === 'phase')[0];
            setPhase(phase ? phase.value : 'over');
        });
    }, []);

    return (
        <Box className="flex flex-col justify-center">
            <img src={logo} alt="NaMoPaiMo logo" className="md:w-2/3 mx-auto"/>
            <Typography 
                variant="h2" 
                className="text-center pb-8">
                {
                    phase === 'reg' ?
                        `${new Date().getUTCFullYear()} Registration`
                    : phase === 'cmp' ?
                    `${new Date().getUTCFullYear()} Completion`
                    : 
                        "The season is closed!"
                }
            </Typography>
            {
                phase === 'reg' ?
                    <RegisterForm />
                : phase === 'cmp' ?
                    <CompleteForm />
                : 
                    "The season is closed!"
            }
        </Box>
    );
}

export default RegisterCompletePage;