import { Box, Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";

const ErrorModal = (props) => {

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}>
            <Box>
                <DialogTitle>
                    Error!
                </DialogTitle>
                <DialogContent>
                    {props.error}
                </DialogContent>
                <Button
                    onClick={props.onClose}
                    fullWidth>
                    OK
                </Button>
            </Box>
        </Dialog>
    );
}

export default ErrorModal;