import React, { useState } from "react";
import AboutPage from "./pages/AboutPage";
import RulesPage from "./pages/RulesPage";
import GalleryPage from "./pages/GalleryPage";
import StatsPage from "./pages/StatsPage";
import RegisterCompletePage from "./pages/RegisterCompletePage";
import { AppBar, Box, IconButton, Typography, Button, Toolbar, Menu, MenuItem, Paper } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import ForgotCode from "./pages/ForgotCode";

function App() {
    const pages = [
        {
            label: 'About',
            component: <AboutPage />
        },
        {
            label: 'Rules',
            component: <RulesPage />
        },
        {
            label: 'Gallery',
            component: <GalleryPage />
        },
        {
            label: 'Stats Page',
            component: <StatsPage />
        },
        {
            label: 'Register / Complete',
            component: <RegisterCompletePage />
        },
        {
            label: 'Forgot Code',
            component: <ForgotCode />
        }
    ]
    const [currentPage, setCurrentPage] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpenNav = (event) => {
        setAnchorEl(event.currentTarget);
    }
    const handleCloseNav = () => {
        setAnchorEl(null);
    }

    return (
        <Box className="w-full min-h-screen pb-8 relative">
            {/* Navigation elements */}
            <AppBar position="static">
                <Toolbar>
                    {/* Menu button for small screens */}
                    <IconButton
                        size="large"
                        edge="start"
                        aria-label="menu"
                        sx={{ display: { sm: 'none' } }}
                        onClick={handleOpenNav}
                    >
                        <MenuIcon />
                    </IconButton>
                    {/* Menu attached to the small screen */}
                    <Menu
                        anchorEl={anchorEl}
                        open={anchorEl !== null}
                        onClose={handleCloseNav}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}>
                        {
                            pages.map((el, i) =>
                                <MenuItem
                                    key={i}
                                    onClick={() => { setCurrentPage(i); handleCloseNav(); }}>
                                    {el.label}
                                </MenuItem>
                            )
                        }
                    </Menu>

                    {/* Small screen header text */}
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ display: { sm: 'none' }, flexGrow: 1 }}>
                        National Model Painting Month
                    </Typography>
                    {/* Large screen header text */}
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ display: { xs: 'none', sm: 'block' }, flexGrow: 1 }}>
                        National Model Painting Month {new Date().getUTCFullYear()}
                    </Typography>

                    {/* Large screen navigation buttons */}
                    {
                        pages.map((el, i) =>
                            <Button
                                key={i}
                                color="inherit"
                                onClick={() => { setCurrentPage(i); }}
                                sx={{ display: { xs: 'none', sm: 'block' } }}>
                                {el.label}
                            </Button>
                        )
                    }
                </Toolbar>
            </AppBar>

            {/* Main content */}
            <Paper className="mx-auto sm:mt-4 md:bg-white p-4 md:rounded-lg md:w-2/3 md:shadow-lg">
                {pages[currentPage].component}
            </Paper>

            {/* Signature4 */}
            <Typography variant="caption" className="absolute text-center inset-x-1 bottom-1">
                Made with ❤️ by Ryan Buxton
            </Typography>
        </Box>
    );
}

export default App;
