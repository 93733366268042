import React, { useState, useEffect } from "react";
import logo from '../img/namopaimo.png'
import { Box, Typography, TextField, Button } from "@mui/material";
import axios from "axios";
import { getAPIURL } from "../helpers/api";

const ForgotCode = (props) => {
    const [email, setEmail] = useState('');
    const [confirmation, setConfirmation] = useState(false);

    const onEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const onSubmit = () => {
        axios.post(getAPIURL('forgot'), {
            email: email
        }).then(res => {
            if (res) {
                setEmail('');
                setConfirmation(true);
            }
        })
    }

    useEffect(() => {
        if (confirmation && email !== '')
            setConfirmation(false);
    }, [email])

    return(
        <Box className="flex flex-col justify-center align-center space-y-2">
            <img src={logo} alt="NaMoPaiMo logo" className="md:w-2/3 mx-auto"/>
            <Typography 
                variant="h2" 
                className="text-center">
                Forgot Code
            </Typography>
            {
                window.sessionStorage.getItem('code') &&
                <Typography>
                    It looks like you used this device to register. Your code is <b>{window.sessionStorage.getItem('code')}</b>. If you're not sure you can still use the form below.
                </Typography>
            }
            <Typography>
                Lost your completion code? No problem! Just tell us the email address you used to register and we’ll resend it.
            </Typography>
            <TextField
                label="Email Address"
                value={email}
                onChange={onEmailChange}
                fullWidth/>
            <Button 
                variant="contained" 
                color={confirmation ? "success" : "primary"} 
                disabled={email === ''}
                onClick={onSubmit}
                fullWidth>
                {confirmation ? "Email sent" : "Forgot Code"}
            </Button>
        </Box>
    );
}

export default ForgotCode;