import { Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import React, { useEffect, useState } from "react";
import VisuallyHiddenInput from "../component/VisuallyHiddenInput";
import { useCountries } from 'use-react-countries';
import axios from "axios";
import { getAPIURL } from "../helpers/api";
import CompletedModal from '../component/CompletedModal';
import ErrorModal from "../component/ErrorModal";

const RegisterForm = (props) => {
    const { countries } = useCountries();
    const [canSubmit, setCanSubmit] = useState(false);
    const [formValues, setFormValues] = useState({
        name: '',
        email: '',
        country: '',
        level: '',
        age: '',
        description: '',
        medium: '',
        color: '',
        goals: '',
        fee: '',
        years: 0,
        image: undefined
    });
    const [code, setCode] = useState('');
    const [error, setError] = useState('');

    const onChangeName = (event) => { setFormValues(prev => { return {...prev, name: event.target.value}; }); }
    const onChangeEmail = (event) => { setFormValues(prev => { return {...prev, email: event.target.value}; }); }
    const onChangeCountry = (event) => { setFormValues(prev => { return {...prev, country: event.target.value}; }); }
    const onChangeLevel = (event) => { setFormValues(prev => { return {...prev, level: event.target.value}; }); }
    const onChangeAge = (event) => { setFormValues(prev => { return {...prev, age: event.target.value}; }); }
    const onChangeDescription = (event) => { setFormValues(prev => { return {...prev, description: event.target.value}; }); }
    const onChangeMedium = (event) => { setFormValues(prev => { return {...prev, medium: event.target.value}; }); }
    const onChangeColor = (event) => { setFormValues(prev => { return {...prev, color: event.target.value}; }); }
    const onChangeGoals = (event) => { setFormValues(prev => { return {...prev, goals: event.target.value}; }); }
    const onChangeFee = (event) => { setFormValues(prev => { return {...prev, fee: event.target.checked}; }); }
    const onChangeYears = (event) => { setFormValues(prev => { return {...prev, years: event.target.value}; }); }
    const onChangeImage = (event) => { setFormValues(prev => { return {...prev, image: event.target.files[0]}; }); }

    const clearForm = () => {
        setFormValues({
            name: '',
            email: '',
            country: '',
            level: '',
            age: '',
            description: '',
            medium: '',
            color: '',
            goals: '',
            fee: '',
            years: 0,
            image: undefined
        });
    }

    const onRegister = () => {
        setCanSubmit(false);

        const formData = new FormData();
        formData.append('image', formValues.image, formValues.image.name);
        formData.append('name', formValues.name);
        formData.append('email', formValues.email);
        formData.append('country', formValues.country);
        formData.append('level', formValues.level);
        formData.append('age', formValues.age);
        formData.append('description', formValues.description);
        formData.append('medium', formValues.medium);
        formData.append('color', formValues.color);
        formData.append('goals', formValues.goals);
        formData.append('fee', formValues.fee);
        formData.append('years', formValues.years);

        axios.post(getAPIURL('register'), formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => {
            if (res.data.code) {
                setCode(res.data.code);
                window.sessionStorage.setItem('code', res.data.code);
            } else {
                setError("Uh oh! There was an error completing that request. Please try again or contact NaMoPaiMo via Facebook if the issue persists.\n" + JSON.stringify(res));
            }
        });
    }

    const onCloseDialog = () => {
        clearForm();
        setCode('');
    }

    useEffect(() => {
        const requiredFeilds = ['name', 'email', 'country', 'level', 'age', 'description', 'medium', 'color', 'years'];

        if (requiredFeilds.map(key => {
            return formValues[key] !== undefined && formValues[key] !== '';
        }).includes(false)){
            setCanSubmit(false);
        } else {
            setCanSubmit(true);
        }
    }, [formValues]);

    return (
        <div className="flex flex-col space-y-2">
            <CompletedModal 
                code={code}
                open={code !== ''}
                onClose={onCloseDialog}/>
            <ErrorModal 
                code={error}
                open={error !== ''}
                onClose={() => { setError(''); }}/>

            <Typography>
                To register for NaMoPaiMo please provide a few details about yourself and your model. Once complete you will be sent an email to the provided address which will contain a code that you will need to provide in order to complete the event. Please do not lose this code!
            </Typography>
 
            <Divider>Personal Information</Divider>
            <TextField 
                    label="Full Name" 
                    variant="outlined" 
                    value={formValues.name}
                    onChange={onChangeName}
                    fullWidth/>
            <TextField 
                label="Email Address" 
                variant="outlined" 
                value={formValues.email}
                onChange={onChangeEmail}
                fullWidth/>
            <div className="flex flex-row space-x-2">
                <FormControl fullWidth>
                    <InputLabel id="age-label">Age Range</InputLabel>
                    <Select 
                        label="Age Range"
                        labelId="age-label"
                        value={formValues.age}
                        onChange={onChangeAge}>
                        <MenuItem value={undefined}></MenuItem>
                        <MenuItem value={"Under 12"}>Under 12</MenuItem>
                        <MenuItem value={"12-20"}>12-20</MenuItem>
                        <MenuItem value={"21-40"}>21-40</MenuItem>
                        <MenuItem value={"40+"}>40+</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel id="skill-label">Skill Level</InputLabel>
                    <Select 
                        label="Skill Level"
                        labelId="skill-label"
                        value={formValues.level}
                        onChange={onChangeLevel}>
                        <MenuItem value={undefined}></MenuItem>
                        <MenuItem value={"First Time Painter"}>First Time Painter</MenuItem>
                        <MenuItem value={"Beginner"}>Beginner</MenuItem>
                        <MenuItem value={"Intermediate"}>Intermediate</MenuItem>
                        <MenuItem value={"Advanced Amateur"}>Advanced Amateur</MenuItem>
                        <MenuItem value={"Professional"}>Professional</MenuItem>
                    </Select>
                </FormControl>
            </div>
            <FormControl fullWidth>
                <InputLabel id="country-label">Country</InputLabel>
                <Select 
                    label="Country"
                    labelId="country-label"
                    value={formValues.country}
                    onChange={onChangeCountry}>
                    <MenuItem value={undefined}></MenuItem>
                    {
                        countries.sort((a, b) => {
                            if (a.name < b.name)
                                return -1;
                            else if (a.name > b.name)
                                return 1;
                            else 
                                return 0;
                        }).map(({ name }) => 
                            <MenuItem
                                key={name}
                                value={name}>
                                {name}
                            </MenuItem>
                        )
                    }
                </Select>
            </FormControl>

            <Divider>Model Information</Divider>
            <TextField
                label="Model Description"
                multiline
                rows={4}
                placeholder="Make / Mold / Scale"
                inputProps={{ maxLength: 250 }}
                value={formValues.description}
                onChange={onChangeDescription}
                fullWidth/>
            <TextField
                label="Media"
                multiline
                rows={4}
                placeholder="Oils, acrylics, pastels, etc."
                inputProps={{ maxLength: 250 }}
                value={formValues.medium}
                onChange={onChangeMedium}
                fullWidth/>
            <TextField
                label="Color"
                multiline
                rows={2}
                placeholder="Color"
                inputProps={{ maxLength: 50 }}
                value={formValues.color}
                onChange={onChangeColor}
                fullWidth/>
            <Typography>
                This image is required and is used in the collages that are made throughout the event and in the final data to show a before and after of all the models painted. Please make sure that the model is featured prominately in the photo.
            </Typography>
            <Button 
                component="label" 
                variant="contained" 
                startIcon={<CloudUploadIcon />}
                color={formValues.image ? 'success' : 'primary'}>
                {formValues.image ? "Reupload Image" : "Upload Image"}
                <VisuallyHiddenInput 
                    type="file" 
                    onChange={onChangeImage}/>
            </Button>

            <Divider>Additional Information</Divider>
            <TextField
                label="Personal Goals or Challenges"
                multiline
                rows={4}
                placeholder="(Optional)"
                inputProps={{ maxLength: 500 }}
                value={formValues.goals}
                onChange={onChangeGoals}
                fullWidth/>
            <FormGroup>
                <FormControlLabel 
                    control={
                        <Checkbox 
                        checked={formValues.fee}
                        onChange={onChangeFee}
                        inputProps={{ 'aria-label': 'controlled' }}/>
                        } 
                    label="Entry fee paid? ($2 entry fee, optional but very much appreciated. PayPal: NaMoPaiMo@gmail.com)" />
            </FormGroup>
            <TextField
                label="How long have you been participating in NaMoPaiMo?"
                value={formValues.years}
                onChange={onChangeYears}
                fullWidth/>

            <Button 
                variant="contained"
                color="success" 
                fullWidth
                disabled={!canSubmit}
                onClick={onRegister}>
                Register
            </Button>
        </div>
    )
}

export default RegisterForm;