import { Button, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import VisuallyHiddenInput from "../component/VisuallyHiddenInput";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CongratulationsModal from "../component/CongratulationsModal";
import axios from "axios";
import { getAPIURL } from "../helpers/api";
import ErrorModal from "../component/ErrorModal";

const CompleteForm = (prop) => {
    const [code, setCode] = useState('');
    const [image, setImage] = useState(undefined);
    const [success, setSuccess] = useState(undefined);

    const onChangeCode = (event) => { setCode(event.target.value); }
    const onChangeImage = (event) => { setImage(event.target.files[0]); }

    const onComplete = () => {
        const formData = new FormData();
        formData.append('image', image, image.name);
        formData.append('code', code);

        axios.post(getAPIURL('complete'), formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => {
            if (res) {
                setSuccess(true);
            } else {
                setSuccess(res);
            }
        });
    }

    const onCloseModal = () => {
        setCode('');
        setImage(undefined);
        setSuccess(undefined);
    }

    return (
        <div className="flex flex-col space-y-2">
            <CongratulationsModal
                open={success === true}
                onClose={onCloseModal}/>
            <ErrorModal
                open={success !== true && success !== undefined}
                onClose={onCloseModal}
                error={success}/>

            <Typography>
                Thank you for registering for NaMoPaiMo. To complete your model please provide your comletion ccode along with an image of the finised model.
            </Typography>
            <TextField
                label="Completion Code"
                value={code}
                onChange={onChangeCode}
                fullWidth/>
            <Button 
                component="label" 
                variant="contained" 
                startIcon={<CloudUploadIcon />}
                color={image ? 'success' : 'primary'}>
                {image ? "Reupload Image" : "Upload Image"}
                <VisuallyHiddenInput 
                    type="file" 
                    onChange={onChangeImage}/>
            </Button>
            <Button 
                variant="contained"
                color="success" 
                onClick={onComplete}
                disabled={image === undefined || code === ''}
                fullWidth>
                Complete
            </Button>
        </div>
    );
}

export default CompleteForm;