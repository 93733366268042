import React from "react";
import logo from '../img/namopaimo.png'
import collage2017 from '../img/2017.jpg';
import collage2018 from '../img/2018.jpg';
import collage2019 from '../img/2019.jpg';
import collage2020 from '../img/2020.jpg';
import collage2021 from '../img/2021.jpg';
import collage2022 from '../img/2022.jpeg';
import collage2023 from '../img/2023.jpeg';
import { Box, Typography } from "@mui/material";

const GalleryPage = (props) => {

    return (
        <Box className="flex flex-col justify-center">
            <img src={logo} alt="NaMoPaiMo logo" className="md:w-2/3 mx-auto"/>
            <Typography 
                variant="h2" 
                className="text-center">
                Gallery
            </Typography>

            <img src={collage2017} alt="NaMoPaiMo 2017" className="md:w-2/3 mx-auto"/>
            <Typography
                variant="caption"
                className="text-center pb-20">
                2017
            </Typography>

            <img src={collage2018} alt="NaMoPaiMo 2018" className="md:w-2/3 mx-auto"/>
            <Typography
                variant="caption"
                className="text-center pb-20">
                2018
            </Typography>

            <img src={collage2019} alt="NaMoPaiMo 2019" className="md:w-2/3 mx-auto"/>
            <Typography
                variant="caption"
                className="text-center pb-20">
                2019
            </Typography>

            <img src={collage2020} alt="NaMoPaiMo 2020" className="md:w-2/3 mx-auto"/>
            <Typography
                variant="caption"
                className="text-center pb-20">
                2020
            </Typography>

            <img src={collage2021} alt="NaMoPaiMo 2021" className="md:w-2/3 mx-auto"/>
            <Typography
                variant="caption"
                className="text-center pb-20">
                2021
            </Typography>

            <img src={collage2022} alt="NaMoPaiMo 2022" className="md:w-2/3 mx-auto"/>
            <Typography
                variant="caption"
                className="text-center pb-20">
                2022
            </Typography>

            <img src={collage2023} alt="NaMoPaiMo 2023" className="md:w-2/3 mx-auto"/>
            <Typography
                variant="caption"
                className="text-center pb-20">
                2023
            </Typography>

        </Box>
    );
}

export default GalleryPage;