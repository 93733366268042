import { Box, Button, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getAPIURL, getImageURL } from "../helpers/api";
import ErrorModal from "../component/ErrorModal";
import axios from 'axios';

const StatsPage = (props) => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(false);
    const [error, setError] = useState(undefined);
    const [phase, setPhase] = useState('reg');
    const PAGE_SIZE = 10;

    const nextPage = () => { setPage(prev => prev + 1); }
    const previousPage = () => { setPage(prev => prev - 1); }

    const loadData = (page) => {
        axios.get(getAPIURL('config')).then((cfg) => {
            let phase = cfg.data.filter(cfg => cfg.configkey === 'phase')[0];
            setPhase(phase.value);

            let formData = new FormData();
            formData.append('offset', page * PAGE_SIZE);

            axios.post(getAPIURL(phase.value === 'reg' ? 'registered' : 'completed'), formData).then(res => {
                if (res) {
                    setData(res.data);
                    setHasMore(res.data.length === PAGE_SIZE)
                } else {
                    setError(res);
                }
            })
        });
    }

    useEffect(() => {
        loadData(page);
    }, [page]);

    return(
        <Box className="flex flex-col justify-center space-y-2">
            <ErrorModal
                open={error}
                error={error}
                onClose={() => { setError(undefined); }}/>

            <Typography
                variant="h2" 
                className="text-center">
                Live Statistics
            </Typography>

            <Divider />

            {
                data?.length > 0 ? data.map(el => 
                        <>
                            {
                                phase === 'reg' ?
                                    <Box className="md:w-2/3 flex flex-row space-x-2 justify-center mx-auto object-contain">
                                        <img src={getImageURL(el.files[0])} alt="Registration" className="w-1/3 h-auto object-contain"/>
                                    </Box>
                                :
                                    <Box className="md:w-2/3 flex flex-row space-x-2 justify-center mx-auto object-contain">
                                        <img src={getImageURL(el.files.filter(file => file.includes('register'))[0])} alt="Registration" className="w-1/3 h-auto object-contain"/>
                                        <img src={getImageURL(el.files.filter(file => file.includes('complete'))[0])} alt="Completion" className="w-1/3 h-auto object-contain"/>
                                    </Box>
                            }
                            
                            <Typography
                                variant="caption"
                                className="text-center pb-10">
                                {el.name}, {el.country}
                            </Typography>
                        </>
                    )
                :
                    <Typography className="text-center py-20">No data to show!</Typography>
            }

            <Divider />

            <Box className="flex justify-evenly">
                <Button
                    variant="outlined"
                    disabled={page <= 0}
                    onClick={previousPage}>
                    Previous 
                </Button>
                <Typography>
                    Current page {page + 1}
                </Typography>
                <Button
                    variant="outlined"
                    disabled={!hasMore}
                    onClick={nextPage}>
                    Next 
                </Button>
            </Box>
        </Box>
    );
}

export default StatsPage;