import { Box, Button, Dialog, DialogContent, DialogTitle, Modal, Typography } from "@mui/material";
import React from "react";

const CongratulationsModal = (props) => {

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}>
            <Box>
                <DialogTitle>
                    Congratulations!
                </DialogTitle>
                <DialogContent>
                    Thank you for participating in NaMoPaiMo! Your completions has been recorded.
                </DialogContent>
                <Button
                    onClick={props.onClose}
                    fullWidth>
                    OK
                </Button>
            </Box>
        </Dialog>
    );
}

export default CongratulationsModal;