import React from "react";
import logo from '../img/namopaimo.png'
import { Box, Typography } from "@mui/material";

const AboutPage = (props) => {

    return (
        <Box className="flex flex-col justify-center align-center space-y-8">
            <img src={logo} alt="NaMoPaiMo logo" className="md:w-2/3 mx-auto"/>

            <Typography>
                Founded by Jennifer Buxton in 2017, National Model Painting Month (NaMoPaiMo) is an internet-based art project that takes place during the month of February. Participants are challenged to paint one model horse between 12:00 a.m. February 1 and 11:59 p.m. February 29. 
            </Typography>

            <Typography>
            This is not a painting contest. The emphasis is on the act of painting itself. Everyone who completes the challenge is a winner. The NaMoPaiMo Facebook page, in conjunction with the Braymere Custom Saddlery blog, provides participants with tutorials, support and motivation throughout the entire process.
            </Typography>

            <Typography>
                The NaMoPaiMo registration period runs from January 18  through January 31. One entry per person.
            </Typography>

            <Typography>
                Although participants are encouraged to paint as many models as they can during the NaMoPaiMo period, only one model can be their official NaMoPaiMo horse. Please choose carefully. Registration is a commitment, and participants will not be allowed to change their minds after the fact.
            </Typography>

            <Typography>
                There is a $2 optional entry fee for this event. Our PayPal address is namopaimo@gmail.com. Please use Goods and Services.
            </Typography>

        </Box>
    );
}

export default AboutPage;